@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:wght@100;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cantata+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap");

* {
  padding: 0 0;
  margin: 0 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

:root {
  --smaller: 0.6;
}

body {
  background: #f1f1f1;
}

.logo {
  width: 220px;
  margin: 0 0 0 -24px;
}

.centering-wrapper {
  max-width: 1250px;
  margin: 0 auto;
  padding: 0 30px;
  position: relative;
}

.mogul-header .centering-wrapper {
  max-width: 1250px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
}

.mogul-header {
  padding: 30px 0;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10000;
  /* backdrop-filter: blur(2px); */
  transition: all ease-in-out 0.2s;
}

.burger-menu {
  display: none;
}

.mogul-header-dark {
  padding: 5px 0;
  /* background:rgba(35,37,38,.9); */
  /* background-color: rgba(5, 24, 47, 0.88); */
  background: #000;
  transition: all ease-in-out 0.3s;
}

.mogul-header a {
  padding: 8px 24px;
}

.mogul-header a.inactive {
  color: rgba(255, 255, 255, 0.85);
  transition: all ease-in-out 0.1s;
  text-decoration: none;
  font-size: 10.7pt;
  -webkit-font-smoothing: antialiased;
  font-weight: 400 !important;
}
.mogul-header a.active,
.mogul-header a:hover {
  color: #fff !important;
  transition: all ease-in-out 0.2s;
  font-weight: 400 !important;
  position: relative;
}

.mogul-header a.active::after {
  content: "";
  display: block;
  background-color: #1145ea;
  width: 4px;
  height: 4px;
  position: absolute;
  left: 5px;
  top: 15px;
}

.cta {
  color: #fff;
  background: #1145ea;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  font-size: 10.7pt;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
}

.cta:hover {
  color: #fff;
  text-decoration: none;
  opacity: 0.85;
}

.HomeSlider {
  background: rgba(5, 24, 47, 1);
  min-height: 60vh;
  padding: 200px 0;
  text-align: center;
  position: relative;
  margin: 0 0;
}

.HomeSlider h1 {
  font-weight: 700;
  color: #fff;
}

/* Нүүр хуудасны баннерны хөндлөн скролл арилгах */
.homePageSlick .slick-slider {
  max-width: 100%;
  overflow: hidden !important;
  background: #2e4053;
}

.homePageSlick .slick-dots {
  bottom: 30px;
}

.homePageSlick .history .slick-prev:before,
.homePageSlick .history .slick-next:before {
  color: #ccc !important;
}

.homePageSlick .slick-dots li button:before {
  color: #1145ea !important;
}

.slider-gradient {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  content: "";
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 72%,
    rgba(3, 9, 57, 0.865983893557423) 100%
  );
}

.slider-context {
  position: absolute;
  display: inline-block;
  left: 50px;
  bottom: 0px;
}

.scrollDownIcon {
  display: block;
  content: "";
  width: 30px;
  height: 55px;
  position: absolute;
  bottom: 100px;
  left: 50%;
  border: 2px solid rgba(255, 255, 255, 0.15);
  transform: translateX(-50%);
  border-radius: 20px;
}

.scrollDownIcon::after {
  display: block;
  content: "";
  width: 5px;
  height: 5px;
  position: absolute;
  top: 10px;
  left: 50%;
  background: #1145ea;
  transform: translateX(-50%);
  border-radius: 20px;
  animation: wheel 2s infinite;
  -webkit-animation: wheel 2s infinite;
}

@keyframes wheel {
  to {
    opacity: 0;
    top: 40px;
  }
}

@-webkit-keyframes wheel {
  to {
    opacity: 0;
    top: 40px;
  }
}

.contacForm {
  padding: 80px 80px;
  background: #f6f6f6;
}

.contactData {
  min-height: 100%;
  border: 1px solid #f6f6f6;
  padding: 80px 80px;
  background: #34495e;
}

.contactData p {
  padding: 10px 0;
  margin: 0 0;
  font-weight: 500;
  color: #eee;
}

.ant-form-item {
  /* margin-bottom: 10px !important; */
}
.footer-area {
  padding: 50px 0;
  background: #fff;
  font-size: 10pt;
  color: #2e4053;
  text-align: center;
  font-feature-settings: normal !important;
}

.footer-menu {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  font-feature-settings: normal !important;
}

.footer-menu p {
  font-weight: 500;
  color: #999;
}

.footer-menu ul {
  padding: 0 0;
  margin: 0 0;
  font-feature-settings: normal !important;
}

.footer-menu ul li {
  list-style: none;
  float: left;
  position: relative;
  line-height: 100%;
  padding: 0 15px;
  font-feature-settings: normal !important;
}

.footer-menu ul li::after {
  display: block;
  width: 2px;
  height: 13px;
  background: #ccc;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
}
.footer-menu ul li:last-child {
  padding: 0 0 0 15px;
}
.footer-menu ul li:last-child::after {
  display: none !important;
}

.footer-menu ul li a {
  display: inline-block;
  color: #2e4053;
  font-weight: 500;
  color: #999;
  font-feature-settings: normal !important;
}

.footer-menu ul li a:hover {
  color: #333;
}

/*Media*/

.media-header {
  padding: 20px 0px 0;
  background-size: cover;
  background-position: center center;
  background: #34495e;
}

.casestudycard {
  border: 1px solid #f4f6f6;
  min-height: 350px;
  background: #fff;
}

.casestudycard p {
  padding: 15px 30px 15px 80px;
  margin: 0 0;
  font-weight: 400;
  color: #999;
  position: relative;
  display: block;
}

.casestudycard p::after {
  display: block;
  width: 30px;
  height: 1px;
  position: absolute;
  content: "";
  background: #1145ea;
  left: 30px;
  top: 25px;
}

.casestudycard h3 {
  line-height: 125%;
  padding: 0 30px;
  color: #34495e;
}
.NewsBox {
  background: #f6f6f6;

  padding: 50px 0;
}

.NewsBox .ant-divider {
  background-color: #fff;
}

.titleSection {
  font-weight: 600;
  margin: 0 0 15px;
  line-height: 100%;
  color: #040e3f;
  text-align: center;
}
.FeaturedNews {
  display: block;
  background: #fff;
}
.FeaturedNews h5 {
  padding: 0 30px;
  font-weight: 600;
  color: blue;
}
.FeaturedNews h2 {
  padding: 0 30px 50px;
  line-height: 120%;
  color: #2e4053;
  font-weight: 600;
}

.FeaturedSmall {
  background: #fff;
  display: block;
  padding: 30px 30px;
  line-height: 130%;
  margin-bottom: 24px;
  border: 1px solid #fff;
}

.FeaturedSmall:hover {
  border: 1px solid #000;
}

.socialimpact-big {
  padding: 0 0;
  background: #fff;
}
.socialimpact-big-intro {
  padding: 80px 50px;
}
.socialimpact-big-intro h1 {
  line-height: 120%;
  color: #333;
}

.socialimpact-big-intro p {
  color: #333;
}

.socialimpact-big-intro h3 {
  color: #999;
  margin-bottom: 30px;
  position: relative;
  display: block;
}

.socialimpact-big-intro h3::after {
  display: block;
  width: 80px;
  height: 3px;
  position: absolute;
  content: "";
  background: #1145ea;
  left: -90px;
  top: 10px;
}

.adminTool {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  background: #f5f5f7;
}

.tox-tinymce {
  border-radius: 0 !important;
  border: 1px solid #eee;
  margin-top: 12px;
}

.PageHeaderMogul {
  padding: 160px 0 100px;
  /* background:rgba(35,37,38,.9); */
  /* background: rgba(5, 24, 47, 0.9); */
  background: rgba(0, 0, 0, 0.8);
  background-image: url("../src/images/dots.png");
  overflow: hidden;
  position: relative;
}
.PageHeaderMogul h2 {
  position: relative;
  padding: 0 0 0 80px;
  margin: 0 0;
  font-weight: 600;
  background: linear-gradient(90deg, #fff 0%, #666 50%, #fff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% auto;
  /* animation: shinetext 1s linear infinite; */
}

.PageHeaderMogul h2::after {
  display: block;
  width: 50px;
  height: 1px;
  content: "";
  background: #1145ea;
  position: absolute;
  left: 0;
  top: 15px;
}

.parallaxHeader {
  padding: 160px 0;
  background: rgba(0, 0, 0, 0.8);
  background-image: url("../src/images/dots.png");
}

.parallaxHeader h2 {
  position: relative;
  padding: 0 0 0 80px;
  margin: 0 0;
  font-weight: 600;
  background: linear-gradient(90deg, #fff 0%, #666 50%, #fff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% auto;
  /* animation: shinetext 1s linear infinite; */
}

.parallaxHeader h2::after {
  display: block;
  width: 50px;
  height: 1px;
  content: "";
  background: #1145ea;
  position: absolute;
  left: 0;
  top: 15px;
}

/*Career хүний нөөц-----------------**/
.careerHeader {
  padding: 250px 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.careerHeader h1 {
  font-weight: 400;
  color: #fff;
  font-size: 35pt;
  margin-bottom: 20px;
  letter-spacing: 1px;
}
.hrTestimonials {
  background: #fff;
  padding: 100px 0;
}

.hrTestimonials h2 {
  padding: 30px 0;
  line-height: 140%;
  position: relative;
}

.hrTestimonials h2:after {
  content: "\201C";
  font-size: 30pt;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.quoteItem {
  padding: 0 30px;
}
.quoteImg {
  display: block;
  width: 100%;
  min-height: 400px;
  background: #eee;
  background-position: "center";
  background-size: "cover";
  background-repeat: "no-repeat";
}

.hrTestimonials .slick-dots {
  position: absolute;
  bottom: 30px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0 0 0 20px;
  list-style: none;
  text-align: left !important;
}

.student {
  background: #f5f5f7;
}

.student .intro {
  padding: 100px 0;
  color: #454545;
  text-align: center;
}

.student header {
  padding: 250px 0;
  min-height: 500px;
  background: rgb(245, 245, 247);

  background-image: url("https://itzone.mn/photos/shares/mogul-web/IMG_1338.jpg");
  background-size: cover;
  background-position: top center;
}

.student header h1 {
  text-align: center;
}

.student .zorilgo {
  padding: 50px 50px;
  color: #fff;
  background: #3483ea;
  border-radius: 15px;
  height: 300px;
}

.student .student-terms {
  padding: 50px 50px;
  color: #454545;
  border: 2px solid #3483ea;
  background: #fff;
  border-radius: 15px;
  height: 300px;
}

.student .zorilgo h3 {
  color: #fff;
  font-weight: 600;
}

.student-request {
  padding: 100px 0;
  background: #fff;
  border-bottom: 1px solid #f5f5f7;
}
.student-request h1 {
  line-height: 125%;
}
.student-request ul {
  margin: 25px 0;
}

.student-request ul li {
  list-style: none;
  border-bottom: 1px solid #eee;
  padding: 10px 0 10px 40px;
  font-weight: 500;
  color: #454545;
  position: relative;
}
.student-request ul li:last-child {
  border-bottom: none;
}

.student-request ul li::after {
  content: "";
  display: block;
  background: #1145ea;
  width: 5px;
  height: 5px;
  border-radius: 1px;
  position: absolute;
  left: 0;
  top: 20px;
}

.student-request img {
  max-width: 100%;
  margin-bottom: 24px;
  border-radius: 15px;
}

.openpositionHeader {
  padding: 150px 0 100px;
  color: #fff;
  background: #101010;
}
.openpositionHeader h1 {
  color: #fff;
  font-size: 30pt;
  line-height: 120%;
  background: linear-gradient(90deg, #f8ff00 0%, #3ad59f 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.openpositionHeader p {
  max-width: 450px;
}

.JobListItem {
  border: none;
  padding: 15px 30px;
  transition: all ease-in-out 0.5s;
  background: #202020;
  margin: 5px 0;
  border-radius: 5px;
}
.JobListItem h3 {
  margin: 0 0;
  color: #fff;
}
.JobListItem:hover {
  background: #303030;
  transition: all ease-in-out 0.2s;
}

.jobDetailsTitle {
  background: linear-gradient(90deg, #00d2ff 0%, #3a47d5 100%);
  height: 100%;
  align-items: center;
  justify-content: center;

  padding: 70px 40px;
  border-radius: 5px 0px 0px 5px;
}

.jobDetailsTitle h1 {
  font-weight: 700;
  line-height: 100%;
  font-size: 35pt;
  color: #101010;
}
.jobDetailsTitle h5 {
  font-size: 13pt;
  display: inline-block;
  border-radius: 0px;
  border-bottom: 1px solid #101010;
  padding: 15px 0;
  margin: 15px 0;
  color: #101010;
}

.jobDetailsbody {
  margin: 0 0;
  border-radius: 0px 5px 5px 0px;
  background: #f5f7f8;
  display: block;
  min-height: 400px;
  padding: 50px 50px;
}

.jobDetailsbody ul li {
  list-style: none;
  padding: 0 0 10px 30px;
  display: block;
  position: relative;
}

.jobDetailsbody ul li::after {
  display: block;
  content: "";
  position: absolute;
  left: 0;
  top: 8px;
  width: 4px;
  height: 4px;
  background: #1145ea;
}

.career-form {
  padding: 100px 100px;
  background: #fff;
}

.career-form h1 {
  border-bottom: 1px solid #000;
  margin-bottom: 50px;
}

.logo-career-form {
  width: 300px;
  margin-bottom: 30px;
}

/* policy pages----------------------- */
.PolicyPage {
  background: #fff;
  padding: 100px 100px;
}

.PolicyPage h1::after {
  content: "";
  display: block;
  width: 100px;
  height: 4px;
  background: #333;
  margin: 15px 0 30px;
}

.PolicyPage h3 {
  border-bottom: 1px solid #eee;
  padding: 30px 0 15px;
  font-weight: 500;
  margin-bottom: 15px;
}

.PolicyPage p {
  color: #666;
}

@keyframes shinetext {
  to {
    background-position: 200% center;
  }
}

.PageHeaderMogul > .ptCover {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  content: "";
  z-index: -1;
  background-size: cover;
  background-position: center center;
  opacity: 1;
}

.article-reading-page {
  border-bottom: 1px solid #eee;
}

.sticky-article-option {
  position: -webkit-sticky;
  position: sticky;
  top: 90px;
  margin-bottom: 30px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.mogul-article-header {
  display: block;
  min-height: 450px;
  height: auto;
  background: rgba(0, 0, 0, 0.7);
  background-image: url("../src/images/dots.png");
  position: relative;
  padding: 150px 0 50px;
}

.mogul-article-header .article-coverimg {
  background-color: #545454;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.mogul-article-header .article-category-link {
  display: inline-block;
  padding: 5px 15px;
  border: 2px solid #1145ea;
  color: #fff;
  text-decoration: none;
  margin: 15px 0;
  border-radius: 5px;
}

.mogul-article-header h5 {
  color: #ccc;
  font-weight: 600;
  letter-spacing: 5px;
}

.mogul-article-header h1 {
  color: #fff;
  line-height: 120%;
}

.mogul-article {
  padding: 30px 0;
}

.mogul-article p {
  color: #666;
}

.mogul-article ul,
.mogul-article ol {
  padding: 0 50px;
}

/*About us-------------------------*/

.overview-intro {
  padding: 100px 0;
  background: #f5f5f7;
}

.overview-intro p {
  color: #999;
}

.stickyTitle {
  position: -webkit-sticky;
  position: sticky;
  top: 90px;
  margin-bottom: 30px;
  color: #545454;
  font-weight: 300;
}

.board-members {
  padding: 50px 0;
  background: #fff;
}

.BoardMember {
  min-height: 350px;
}

.BoardMember:hover > .BoardMemberThumb {
  transition: all ease-in-out 0.2s;
  filter: grayscale(0);
}
.BoardMemberThumb {
  transition: all ease-in-out 0.2s;
  filter: grayscale(1);
  /* border-radius: 10px; */
  overflow: hidden;
}

.history {
  background: #f7f7f5;
  padding: 100px 0;
  color: #666;
}

.history .slick-prev:before,
.history .slick-next:before {
  color: #ccc !important;
}

.history .slick-dots li button:before {
  color: #1145ea !important;
}

.history h1 {
  font-weight: 300;
  color: #545454;
  margin-bottom: 30px;
}

.timelineItem {
  padding: 24px 0px;
  background: #f7f7f5;
  margin: 0 15px;
}

.timelineItem h1 {
  color: #545454;
  font-weight: 900;
  color: #545454;
  margin: 15px 0;
  padding: 15px 0;
  line-height: 100%;
  position: relative;
  background-color: #f7f7f5;
  letter-spacing: 0 !important;
  font-family: "Roboto", sans-serif;
}

.timelineItem h1::after {
  content: "";
  width: calc(100% - 100px);
  height: 1px;
  background: #ccc;
  display: block;
  position: absolute;
  top: 28px;
  right: 0;
}

.timelineItem h4 {
  color: #545454 !important;
  font-weight: 600;
  font-size: 13pt;
  line-height: 130%;
}

.timelineItem p {
  color: #999;
}

.mission {
  margin: 220px auto;
  text-align: center;
}
.mission h4 {
  display: inline-block;
  background: #1145ea;
  color: #fff;
  padding: 8px 45px;
  text-transform: uppercase;
  margin-bottom: 30px;
}
.mission h1 {
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.numericDataItem {
  text-align: center;
  background: #f5f5f7;
  min-height: 250px;
  padding: 30px 30px;
  transition: all ease-in-out 0.2s;
}

.numericDataItem:hover {
  transform: translateY(-10px);
}

.numericDataItem h1 {
  color: #454545;
  line-height: 110%;
  font-weight: 300 !important;
}

.numericDataItem h3 {
  color: #999;
  line-height: 110%;
}
.CompanyProfileBox {
  background-color: #fff;
  min-height: 500px;
  border: 1px solid rgba(0, 0, 0, 0.02);
  transition: all ease-in-out 0.2s;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
}

.CompanyProfileBox .weblink-out {
  display: block;
  position: absolute;
  bottom: 30px;
  left: 30px;
  padding: 8px 20px;
  border-radius: 6px;
  background-size: 200%;
  background: linear-gradient(45deg, #00d2ff 0%, #3a47d5 100%);
  color: #fff;
}

.CompanyProfileBox .weblink-out:hover {
  display: block;
  position: absolute;
  bottom: 30px;
  left: 30px;
  padding: 8px 20px;
  border-radius: 6px;
  background-size: 100%;
  transition: all ease-in-out 1s;
  background: linear-gradient(45deg, #00d2ff 0%, #3a47d5 100%);
  color: #fff;
}

.CompanyProfileBox .weblink-out:hover > .anticon {
  margin-left: 50px;
  transition: all ease-in-out 0.3s;
}

.CompanyProfileBox:hover {
  border: 1px solid rgba(0, 0, 0, 0.1);
  transition: all ease-in-out 0.2s;
  transform: translateY(-0px);
}
.CompanyProfileBox:hover > div {
  filter: grayscale(0);
  opacity: 1;
}

.CompanyProfileBox > div {
  position: relative;
  opacity: 0.85;
  transition: all ease-in-out 0.2s;
}
.CompanyProfileBox > div > a {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
}

.CompanyProfileBox ul {
  padding: 0 36px 24px;
  display: block;
}

.CompanyProfileBox ul li {
  list-style: none;
  display: block;
  padding: 3px 10px;
  font-size: 10.4pt;
  margin-bottom: 6px;
  margin-right: 6px;
  border-radius: 5px;
  color: #999;
  line-height: 100%;
  position: relative;
}

.CompanyProfileBox ul li::after {
  display: block;
  width: 3px;
  height: 3px;
  position: absolute;
  left: 0;
  top: 8px;
  content: "";
  background: #666;
}

.PageNotFound {
  display: flex;
  height: 80vh;
  background: #050514;
  align-items: center;
  background-image: url("https://itzone.mn/photos/shares/mogul-web/earth-2021-08-30-09-19-14-utc.jpg");
  background-size: cover;
  background-position: center center;
}

.PageNotFound h1 {
  color: #efefef;
  font-size: 50pt;
  margin: 0 0 10px;
  line-height: 100%;
}

.PageNotFound h3 {
  color: #ccc;
  margin-bottom: 30px;
}

.float {
  animation: float 6s ease-in-out infinite;
  font-size: 30pt;
  margin: 0 0;
}

.ant-drawer {
  z-index: 10000 !important;
}

.ant-drawer-body {
  padding: 0 !important;
}

.avatar-upload {
  margin-top: 30px;
  float: right;
}

.career-form-wrapper {
  background-color: #eeeeee;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

@media only screen and (max-width: 1000px) {
  .mogul-header nav {
    display: none;
  }

  .burger-menu {
    display: inline-block;
  }

  .nav-buttons {
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .drawer-nav-link {
    display: flex;
    flex-direction: column;
    font-size: 16pt;
    font-weight: 500;
    width: 100%;
  }

  .drawer-nav-link-item {
    border-bottom: 1px solid #ccc;
    color: #1a1919;
    padding: 20px 24px;
    cursor: pointer;
  }

  .footer-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .career-form {
    padding: 20px 20px;
  }

  .avatar-upload {
    float: left;
  }

  .jobDetailsTitle {
    padding: 70px 30px;
  }

  .jobDetailsTitle h1 {
    font-size: calc(3.375rem * var(--smaller));
  }

  .jobDetailsbody {
    padding: 50px 30px;
  }
}

@media only screen and (max-width: 600px) {
  body {
    background-color: lightblue;
  }

  #cta-btn {
    display: none;
  }

  .jobDetailsTitle {
    border-radius: 5px 5px 0px 0px;
  }

  .jobDetailsbody {
    border-radius: 0px 0px 5px 5px;
  }

  .career-form-wrapper {
    background-color: #fff;
  }

  .custom-wrapper {
    padding: 20px 10px;
  }

  .footer-menu-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .footer-area {
    padding: 50px 0;
    background: #fff;
    font-size: 10pt;
    color: #2e4053;
    text-align: center;
  }

  .footer-menu p {
    font-weight: 500;
    color: #999;
  }

  .JobListItem {
    margin: 10px 0;
  }
}

.ant-upload.ant-upload-select-picture-card {
  height: 21vh !important;
  width: 21vh !important;
}
